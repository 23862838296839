import './app.scss';
import 'lazysizes';
import htmx from 'htmx.org';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const accordionSections = document.querySelectorAll('.accordion-section') as NodeListOf<HTMLElement>;
    if (accordionSections.length) {
        import('./ts/accordion').then((faq) => {
            accordionSections.forEach(function (section) {
                faq.default.init(section);
            });
        });
    }

    const accordionImageSections = document.querySelectorAll('.accordion-image') as NodeListOf<HTMLElement>;
    if (accordionImageSections.length) {
        import('./ts/accordion-image').then((faq) => {
            accordionImageSections.forEach(function (section) {
                faq.default.init(section);
            });
        });
    }

    const quoteSliders = document.querySelectorAll('.quote-slider') as NodeListOf<HTMLElement>;
    if (quoteSliders.length) {
        import('./ts/text-image-slider').then((slider) => {
            quoteSliders.forEach(function (section) {
                slider.default.init(section);
            });
        });
    }

    const blogComponents = document.querySelectorAll('.blog-component') as NodeListOf<HTMLElement>;
    if (blogComponents.length) {
        import('./ts/blog-filter-buttons').then((blog) => {
            blogComponents.forEach(function (section) {
                blog.default.init(section);
            });
        });
    }

    const contactTeaserSections = document.querySelectorAll('.contact-teaser');
    if (contactTeaserSections.length) {
        contactTeaserSections.forEach((section) => {
            import('./ts/contact-teaser').then((contactTeaser) => {
                contactTeaser.default.init(section);
            });
        });
    }

    const services = document.querySelectorAll('.services');
    if (services.length) {
        services.forEach((section) => {
            import('./ts/services').then((service) => {
                service.default.init(section);
            });
        });
    }

    const menu = document.getElementById('header-menu');
    if (menu) {
        import('./ts/header').then((header) => {
            header.default.init();
        });
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll('.swiper-container') as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/swiper').then((swiper) => {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.default.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
            });
        });
    }

    const forms = document.querySelectorAll('div[data-form-id]');
    if (forms.length) {
        import('./ts/form').then((form) => {
            form.default.init();
        });
    }

    // no element request because element will be generated via js, only used once
    import('./ts/cookie-banner').then((cookieBanner) => {
        cookieBanner.default.init();
    });

    const faqPage = document.querySelector('.faq-page') as HTMLElement;
    if (faqPage) {
        import('./ts/faq-accordion').then((faqAccordion) => {
            faqAccordion.default.init(faqPage);
        });
    }

    htmx.on('htmx:afterSwap', function () {
        // recreate js after sprig reloads something
        const blogComponents = document.querySelectorAll('.blog-component') as NodeListOf<HTMLElement>;
        if (blogComponents.length) {
            import('./ts/blog-filter-buttons').then((blog) => {
                blogComponents.forEach(function (section) {
                    blog.default.init(section);
                });
            });
        }
    });
})();
